import React, { FC } from "react"
import { NotFound } from "shared"

import { useLocalizationContext } from "@context"

/**
 * Страница 404
 *
 * @returns {FC} - FC
 */
const NotFoundPage: FC = () => {
  const { l } = useLocalizationContext()

  return <NotFound l={l} />
}

export default NotFoundPage
