import { Checkbox } from "@mui/material"
import React, { useState, FC } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { Navigate, useNavigate } from "react-router-dom"
import { NewTextField } from "shared"

import logo from "@assets/images/logo.svg"
import { useTypeActionsHook, useTypedSelectorHook } from "@utils"
import { useLocalizationContext } from "@context"
import { routePaths } from "@constants"
import { PasswordField } from "./fragments"
import { SButton, SContainer, SFormControlLabel, SFormGroup, SLogo, SStack, SAuthError } from "./_styles"

interface IFormInputLogin {
  username: string
  password: string
}

/**
 * Страница входа в систему Echo
 *
 * @returns {FC} - FC
 */
const LoginPage: FC = () => {
  const navigate = useNavigate()
  const { postLoginAction, getOrganizationAction } = useTypeActionsHook()
  const { isAuthRequest, isAuth, isAuthError } = useTypedSelectorHook(({ main }) => main)

  const {
    l,
    l: { ui },
  } = useLocalizationContext()

  const [rememberMe, setRememberMe] = useState<boolean>(true)
  const {
    handleSubmit,
    formState: {
      errors: { username, password },
    },
    control,
  } = useForm<IFormInputLogin>({
    defaultValues: {
      username: "",
      password: "",
    },
  })

  const eventLogin: SubmitHandler<IFormInputLogin> = (data): void => {
    if (username && password) return

    postLoginAction(data, () => {
      getOrganizationAction()
      navigate(routePaths.DASHBOARD, { replace: true })
    })
  }

  if (isAuth) return <Navigate to={routePaths.DASHBOARD} replace />

  return (
    <SContainer>
      <SFormGroup>
        <SLogo src={logo} alt="Echo" />
        <form onSubmit={handleSubmit(eventLogin)}>
          <NewTextField
            name="username"
            label={ui.login}
            control={control}
            isRequired={true}
            error={username?.message}
            requiredFieldMessage={ui.requiredField}
            l={l}
          />
          <PasswordField control={control} error={password?.message} />
          {isAuthError && <SAuthError error={!!isAuthError} children={ui.wrongCredentials} />}
          <SStack>
            <SButton type="submit" variant="contained" disabled={isAuthRequest} children={ui.signIn} size="large" />
            <SFormControlLabel
              label={ui.rememberMe}
              control={<Checkbox checked={rememberMe} onChange={(): void => setRememberMe(!rememberMe)} />}
              disabled
            />
          </SStack>
        </form>
      </SFormGroup>
    </SContainer>
  )
}

export default LoginPage
