import React, { FC, ReactElement, useState } from "react"
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  styled,
} from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { Controller, Control } from "react-hook-form"

import { useLocalizationContext } from "@context"

interface IPasswordField {
  control: any
  error?: string
}

/**
 * Фрагмент поля ввода пароля
 *
 * @param {any} control - Значение поле ввода пароля
 * @param {string} error - Сообщение об ошибке
 * @returns {FC<IPasswordField>} - FC
 */
export const PasswordField: FC<IPasswordField> = ({ control, error }) => {
  const {
    l: { ui },
  } = useLocalizationContext()

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const isError = typeof error !== "undefined"

  return (
    <SFormControl fullWidth variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password" error={isError}>
        {ui.password}
      </InputLabel>
      <Controller
        render={({ field }): ReactElement => (
          <OutlinedInput
            label={ui.password}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(): void => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
            error={isError}
            type={showPassword ? "text" : "password"}
            {...field}
          />
        )}
        control={control}
        name="password"
        rules={{
          required: ui.requiredField,
        }}
      />
      {isError && <FormHelperText error={isError} children={error} />}
    </SFormControl>
  )
}

const SFormControl = styled(FormControl)({
  margin: "20px 0 0",
})
