import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"

import { store } from "@store"
import App from "./App"

const container: HTMLElement | DocumentFragment = document.getElementById("root") as HTMLElement
const root = createRoot(container)

root.render(<Provider store={store} children={<App />} />)
